import axios from "axios";
import React, { useEffect, useState } from "react";
import ModalMessage from "../../modal/ModalMessage";
import ModalConfirm from "../../modal/ModalConfirm";
import styled from "styled-components";
import { keyframes } from "styled-components";
import Loader from "../../loader/LoaderSpinner";
import { FaTrash } from "react-icons/fa";
import { authenticate,authParams } from "../../requests/authenticate";

const slideIn = keyframes`
  from {
    transform:translateX(100vw);
  }
  to{transform:translateX(0);}
`;

const Wrapper = styled.div`
  border-radius: 1rem;
  padding: 7px;
  background-color: white;
  flex: auto;
  text-align: right;
  animation: ${slideIn} 0.7s;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border-radius: 0.5rem 0.5rem 0 0;
  padding: 0.5rem 0.5rem;
  color: white;
  background-color: #9c5d8a;
`;

const Title = styled.h3`
  padding: 0.5em;
  border-radius: 0.5rem 0.5rem 0 0;
  margin: 0;
  color: white;
`;

const UserInput = styled.input`
  border-radius: 0.5rem;
  border 2px solid rgba(0, 0, 0, 0.2);
  margin-right: 2%;
  width: 95%;
  height: 30px;
  margin-bottom: 5px
`;
const UserLabel = styled.label`
  color: #9c5d8a;
  margin-right: 3%;
`;
const UserPermission = styled.select`
border-radius: 0.5rem;
border 2px solid rgba(0, 0, 0, 0.2);
margin-right: 2%;
width: 96%;
height: 35px;
margin-bottom: 5px
`;

const Button = styled.button`
  margin: 10px;
  padding: 5px;
  color: white;
  font-size: 12px;
  border: none;
  border-radius: 1rem;
  transition: 0.5s;
  &:hover {
    cursor: pointer;
    box-shadow: 0 0px 2px 1px rgba(0, 0, 0, 0.2);
  }
  background-color: #f59e0b;
`;
const TrashIconContainer = styled.div`
  &:hover {
    cursor: pointer;
  }
`;

export default function UserSettings({ user, setUsers }) {
  const [accessFullName, setAccessFullName] = useState("");
  const [accessEmail, setAccessEmail] = useState("");
  const [accessPassword, setAccessPassword] = useState("");
  const [accessLevel, setAccessLevel] = useState("2");
  const [isLoaderShown, setIsLoaderShown] = useState(false);
  const [isModalShown, setIsModalShown] = useState(false);
  const [message, setMessage] = useState("");
  const [isModalConfirmShown, setIsModalConfirmShown] = useState(false);

  useEffect(() => {
    if (user != null) {
      setAccessFullName(user.AccessFullName);
      setAccessEmail(user.AccessEmail);
      setAccessPassword(user.AccessPassword);
      setAccessLevel(user.AccessLevel);
    } else {
      setAccessFullName("");
      setAccessEmail("");
      setAccessPassword("");
      setAccessLevel(2);
    }
  }, [user]);

  const validationFunc = (validationFields) => {
    for (let i = 0; i < validationFields.length; i++) {
      if (validationFields[i].length < 3) return false;
    }
    return true;
  };

  const handleSubmit = () => {
    if (user != null) {
      const userObj = {
        userId: 28,
        userObj: {
          userId: user.AccessID,
          fullName: accessFullName,
          password: accessPassword,
          accessEmail: accessEmail,
          accessLevel: accessLevel,
        },
      };
      if (validationFunc([accessFullName, accessPassword, accessEmail])) {
        setIsLoaderShown(true);
        axios
          .post(process.env.REACT_APP_BASE_URI + "/doEditAccess.php", userObj,authParams())
          .then((res) => {
            setUsers(res.data.access);
            setIsLoaderShown(false);
          });
        setMessage("השינויים נשמרו בהצלחה");
        setIsModalShown(true);
      } else {
        setMessage("אנא מלא את כל השדות");
        setIsModalShown(true);
      }
    } else {
      const userObj = {
        userId: 28,
        userObj: {
          fullName: accessFullName,
          password: accessPassword,
          email: accessEmail,
          accessLevel: accessLevel,
        },
      };
      if (validationFunc([accessFullName, accessPassword, accessEmail])) {
        setIsLoaderShown(true);
        axios
          .post(process.env.REACT_APP_BASE_URI + "/addAccess.php", userObj,authParams())
          .then((res) => {
            setUsers(res.data.access);
            setIsLoaderShown(false);
            setMessage("השינויים נשמרו בהצלחה");
            setIsModalShown(true);
            setAccessFullName("");
            setAccessEmail("");
            setAccessPassword("");
            setAccessLevel(2);
          });
      } else {
        setMessage("אנא מלא את כל השדות");
        setIsModalShown(true);
      }
    }
  };

  const handleDelete = (userId) => {
    const userObj = {
      userId: 28,
      userObj: { userId: userId },
    };
    setIsLoaderShown(true);
    axios
      .post(process.env.REACT_APP_BASE_URI + "/doDeleteUser", userObj,authParams())
      .then((res) => {
        setUsers(res.data.access);
        setMessage("המשתמש נמחק בהצלחה");
        setIsLoaderShown(false);
        setIsModalShown(true);
      });
  };

  return (
    <Wrapper>
      {isModalConfirmShown && (
        <ModalConfirm
          username={user.AccessFullName}
          setIsModalVisible={setIsModalConfirmShown}
          setMessage={setMessage}
          setUsers={setUsers}
          setIsModalShown={setIsModalShown}
          user={user}
        />
      )}
      <Header>
        <Title>הגדרות משתמש</Title>
        {user && localStorage.getItem("accessLevel") == 0 && (
          <TrashIconContainer onClick={() => setIsModalConfirmShown(true)}>
            <FaTrash></FaTrash>
          </TrashIconContainer>
        )}
      </Header>
      <UserLabel id="fullName">שם מלא</UserLabel>
      <UserInput
        onChange={(e) => setAccessFullName(e.target.value)}
        value={accessFullName}
      />
      <UserLabel id="email">כתובת מייל</UserLabel>
      <UserInput
        onChange={(e) => setAccessEmail(e.target.value)}
        value={accessEmail}
      />
      <UserLabel id="password">סיסמה</UserLabel>
      <UserInput
        onChange={(e) => setAccessPassword(e.target.value)}
        type="password"
        value={accessPassword}
      />
      <UserLabel id="userPermission">רמת הרשאה</UserLabel>
      <UserPermission
        onChange={(e) => setAccessLevel(e.target.value)}
        value={accessLevel}
      >
        <option value="0">מנהל על</option>
        <option value="1">מנהל</option>
        <option value="2">מדריך</option>
      </UserPermission>
      <div style={{ textAlign: "center" }}>
        <Button onClick={handleSubmit}>שמירת שינויים</Button>
      </div>
      {isLoaderShown && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
            paddingTop: "10rem",
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: "20",
          }}
        >
          <div>
            <Loader />
          </div>
        </div>
      )}
      {isModalShown && (
        <ModalMessage setIsModalShown={setIsModalShown} message={message} />
      )}
    </Wrapper>
  );
}
