import React, { useEffect, useState } from "react";
import fileIcon from "../../../assets/img/icon-survey.png";
import styled from "styled-components";

const FileIconWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: end;
  height: 50px;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  height: 3rem;
  padding: 0.5rem;
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const Notifcation = styled.span`
  dispaly: flex;
  align-items: center;
  border-radius: 100%;
  background-color: red;
  position: absolute;
  right: 0;
  top: 0;
  padding: 1px 7px;
  color: white;
  font-weight: 500;
`;

const Text = styled.p`
  margin: 0;
  font-size: 14px;
`;

export default function SurveySnippet(props) {
  const boldText = ["900", "400"];

  return (
    <Wrapper
      onClick={() => {
        for (let i = 0; i < props.surveys.length; i++) {
          if (props.surveys[i].details.SurveyID == props.surveyID) {
            props.setSelectedSurvey(i);
          }
        }
        props.setBgIndex(props.index);
      }}
      style={{ fontWeight: boldText[props.bgIndex == props.index ? 0 : 1] }}
    >
      <FileIconWrapper>
        <Notifcation>{props.SurveyAnswered}</Notifcation>
        <img width={"40px"} height={"40px"} src={fileIcon} />
      </FileIconWrapper>
      <div>
        <Text>
          משוב {props.SurveyID}: {props.SurveyName}
        </Text>
        <Text>{props.SurveyDate}</Text>
      </div>
      <span>{">"}</span>
    </Wrapper>
  );
}
