import React, { useState } from "react";
import styled from "styled-components";
import UserSnippet from "./UserSnippet";

const Wrapper = styled.div`
  border-radius: 1rem;
  padding: 7px;
  flex: 2;
  background-color: white;
`;

const Title = styled.h3`
  padding: 0.5em;
  border-radius: 0.5rem 0.5rem 0 0;
  margin: 0;
  color: white;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 0.5rem 0.5rem 0 0;
  margin: 0;
  padding: 0.5rem 0.5rem;
  color: white;
  background-color: #9c5d8a;
`;

const Button = styled.button`
  margin: 8% 0 0 15%;
  color: white;
  width: 100%;
  font-size: 12px;
  border: none;
  border-radius: 0.5rem;
  transition: 0.5s;
  &:hover {
    cursor: pointer;
    box-shadow: 0 0px 2px 1px rgba(0, 0, 0, 0.2);
  }
  background-color: #f59e0b;
`;

export default function Users({ usersData, setClickedUser }) {
  const [bgIndex, setBgIndex] = useState();
  return (
    <Wrapper>
      <Header>
        <Title>הרשאות גישה</Title>
        <div>
          <Button onClick={() => setClickedUser(-1)}>הוספת הרשאת גישה</Button>
        </div>
      </Header>
      <div>
        {usersData.map((user, i) => {
          return (
            <UserSnippet
              user={user}
              key={i}
              setClickedUser={setClickedUser}
              index={i}
              setBgIndex={setBgIndex}
              bgIndex={bgIndex}
            />
          );
        })}
      </div>
    </Wrapper>
  );
}
