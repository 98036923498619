import React, { useState } from "react";
import styled from "styled-components";
import { FaSearch } from "react-icons/fa";
import SurveySnippet from "./SurveySnippet";
import { keyframes } from "styled-components";
import Modal from "../../modal/Modal";

const slideIn = keyframes`
  from {
    transform:translateX(100vw);
  }
  to{transform:translateX(0);}
`;

const Wrapper = styled.div`
  border-radius: 1rem;
  padding: 7px;
  flex: 4;
  background-color: white;
  animation: ${slideIn} 0.5s;
  flex-shrink: 2;
`;

const Title = styled.h3`
  padding: 0.5em;
  border-radius: 0.5rem 0.5rem 0 0;
  margin: 0;
  color: white;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 0.5rem 0.5rem 0 0;
  margin: 0;
  padding: 0.5rem 0.5rem;
  color: white;
  background-color: #9c5d8a;
`;

const Button = styled.button`
  margin: 13% 0 0 15%;
  color: white;
  width: 100%;
  font-size: 12px;
  border: none;
  border-radius: 0.5rem;
  transition: 0.5s;
  &:hover {
    cursor: pointer;
    box-shadow: 0 0px 2px 1px rgba(0, 0, 0, 0.2);
  }
  background-color: #f59e0b;
`;

const SearchInput = styled.input`
  border: none;
  outline: none;
  background-color: transparent;
`;
const SearchInputWrapper = styled.div`
  position: relative;
  padding: 0.4rem 1rem;
  border-radius: 0.3rem;
  background-color: white;
  margin: 0.3rem 1rem;
  background-color: rgba(0, 0, 0, 0.1);
`;

export default function Surveys({ surveys, setSelectedSurvey, setSurveys }) {
  const [bgIndex, setBgIndex] = useState();
  const [isModalShown, setIsModalShown] = useState(false);
  const [searchWord, setSearchWord] = useState("");
  return (
    <Wrapper>
      {isModalShown && (
        <Modal
          surveys={surveys}
          setSurveys={setSurveys}
          setIsModalVisible={setIsModalShown}
          addSurvey={true}
        />
      )}
      <Header>
        <Title>משובים</Title>
        <div>
          <Button onClick={() => setIsModalShown(true)}>הוספת משוב</Button>
        </div>
      </Header>
      <div
        style={{
          borderBottom: "2px solid rgba(0, 0, 0, 0.1)",
          marginTop: "0.5rem",
        }}
      >
        <SearchInputWrapper>
          <SearchInput
            type="text"
            placeholder="הזן טקסט לחיפוש..."
            onChange={(e) => setSearchWord(e.target.value)}
          />
          <FaSearch style={{ position: "absolute", left: "3%" }} />
        </SearchInputWrapper>
      </div>
      {surveys
        .filter((survey, i) => survey.details.SurveyName.includes(searchWord))
        .map((survey, i) => {
          return (
            <SurveySnippet
              bgIndex={bgIndex}
              setBgIndex={setBgIndex}
              {...survey.details}
              key={survey.details.SurveyID}
              setSelectedSurvey={setSelectedSurvey}
              index={i}
              surveyID={survey.details.SurveyID}
              surveys={surveys}
            />
          );
        })}
    </Wrapper>
  );
}
