import React, { useEffect, useState, useLayoutEffect } from "react";
import styled from "styled-components";
import Surveys from "./surveys/Surveys";
import axios from "axios";
import Loader from "../loader/LoaderFly";
import SurveysQuestions from "./survey_questions/SurveysQuestions";
import SurveySetting from "./survey_settings/SurveySetting";
import { authenticate, authParams } from "../requests/authenticate";

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
`;

export default function Dashboard({ isAuth, isLogged }) {
  const [surveys, setSurveys] = useState();
  const [selectedSurvey, setSelectedSurvey] = useState();

  useEffect(() => {
    axios
      .post(
        process.env.REACT_APP_BASE_URI + "/loadDashboard.php",
        28,
        authParams()
      )
      .then((res) => {
        setSurveys(res.data.surveys);
      });
  }, []);

  return (
    <Wrapper>
      {surveys ? (
        <>
          <Surveys
            setSurveys={setSurveys}
            surveys={surveys}
            setSelectedSurvey={setSelectedSurvey}
          />
          <SurveysQuestions
            surveys={surveys}
            setSurveys={setSurveys}
            selectedSurvey={surveys[selectedSurvey]}
          />
          <SurveySetting
            setSurveys={setSurveys}
            selectedSurvey={selectedSurvey}
            surveys={surveys}
          />
        </>
      ) : (
        <Loader />
      )}
    </Wrapper>
  );
}
