import axios from "axios";
import React, { useState } from "react";
import styled from "styled-components";
import ModalMessage from "./ModalMessage";
import { keyframes } from "styled-components";
import Surveys from "../dashboard/surveys/Surveys";
import { serialize } from "../utils/helpers";
import Loader from "../loader/LoaderSpinner";
import { authenticate,authParams } from "../requests/authenticate";

const slideIn = keyframes`
  from {
    transform:translateY(-100vw);
  }
  to{transform:translateY(0);}
`;

const Wrapper = styled.form`
  position: relative;
  background-color: white;
  padding: 4rem 6rem;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  text-align: right;
  border: 2px solid #808080;
  border-radius: 0.5rem;
  animation: ${slideIn} 0.2s;
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  display: flex;
  justify-content: center;
  align-items: start;
  z-index: 10;
`;

const ExitButton = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.5rem;
  font-weight: 900;
  font-size: 20px;
  &:hover {
    cursor: pointer;
  }
`;

const Input = styled.input`
  border-style: none none solid none;
  border-width: 2px;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 2%;
  &:focus {
    outline-style: none;
    border-bottom: solid #c026d3 2px;
  }
`;

const Select = styled.select`
  border-style: none none solid none;
  border-width: 2px;
  background-color: rgba(0, 0, 0, 0.05);
  width: 20rem;
  padding: 2%;
  &:focus {
    outline-style: none;
    border-bottom: solid #c026d3 2px;
  }
`;

const Button = styled.button`
  color: white;
  width: 100%;
  font-size: 12px;
  border: none;
  transition: 0.5s;
  &:hover {
    cursor: pointer;
    box-shadow: 0 0px 2px 1px rgba(0, 0, 0, 0.2);
  }
  padding: 0.5rem;
`;

const Textarea = styled.textarea`
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.05);
  margin: 0;
  resize: vertical;
`;

export default function ModalConfirm({
  setIsModalVisible,
  username,
  setUsers,
  setMessage,
  setIsModalShown,
  user,
}) {
  const [isLoaderShown, setIsLoaderShown] = useState(false);

  const handleDelete = (userId) => {
    const userObj = {
      userId: 28,
      userObj: { userId: userId },
    };
    setIsLoaderShown(true);
    axios
      .post(process.env.REACT_APP_BASE_URI + "/doDeleteUser", userObj,authParams())
      .then((res) => {
        setUsers(res.data.access);
        setMessage("המשתמש נמחק בהצלחה");
        setIsLoaderShown(false);
        setIsModalShown(true);
        setIsModalVisible(false);
      });
  };

  return (
    <Background>
      <Wrapper
        onSubmit={(event) => {
          event.preventDefault();
        }}
      >
        <ExitButton onClick={() => setIsModalVisible(false)}>X</ExitButton>
        <span style={{ padding: "10px", margin: "0 0 10px 0" }}>
          האם ברצונך למחוק את {username}
        </span>
        <div style={{ display: "flex", alignItems: "center" }}>
          <Button
            style={{ backgroundColor: "#f59e0b" }}
            onClick={() => handleDelete(user.AccessID)}
          >
            אישור
          </Button>
          <Button
            onClick={() => setIsModalVisible(false)}
            style={{ backgroundColor: "#808080" }}
          >
            ביטול
          </Button>
        </div>
      </Wrapper>
      {isLoaderShown && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
            paddingTop: "10rem",
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: "20",
          }}
        >
          <div>
            <Loader />
          </div>
        </div>
      )}
    </Background>
  );
}
