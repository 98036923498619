import axios from "axios";
import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Loader from "../../loader/LoaderSpinner";
import { keyframes } from "styled-components";
import ModalMessage from "../../modal/ModalMessage";
import { authenticate,authParams } from "../../requests/authenticate";

const slideIn = keyframes`
  from {
    transform:translateX(-100vw);
  }
  to{transform:translateX(0);}
`;

const Wrapper = styled.div`
  position: relative;
  border-radius: 1rem;
  padding: 7px;
  background-color: white;
  flex: 3;
  text-align: right;
  animation: ${slideIn} 0.5s;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 0.5rem 0.5rem 0 0;
  padding: 0.5rem 0.5rem;
  color: white;
  background-color: #9c5d8a;
`;

const Title = styled.h3`
  padding: 0.5em;
  border-radius: 0.5rem 0.5rem 0 0;
  margin: 0;
  color: white;
`;

const SettingInput = styled.input`
  border-radius: 0.5rem;
  border 2px solid rgba(0, 0, 0, 0.2);
  margin-right: 2%;
  width: 95%;
  height: 30px;
  margin-bottom: 5px
`;
const SettingLabel = styled.label`
  color: #9c5d8a;
  margin-right: 3%;
`;

const SurveyType = styled.select`
border-radius: 0.5rem;
border 2px solid rgba(0, 0, 0, 0.2);
margin-right: 2%;
width: 96%;
height: 35px;
margin-bottom: 5px
`;

const Textarea = styled.textarea`
  border: 2px solid rgba(0, 0, 0, 0.2);
  margin-right: 2%;
  width: 95%;
  resize: vertical;
`;

const Button = styled.button`
  margin: 10px;
  padding: 5px;
  color: white;
  font-size: 12px;
  border: none;
  border-radius: 1rem;
  transition: 0.5s;
  &:hover {
    cursor: pointer;
    box-shadow: 0 0px 2px 1px rgba(0, 0, 0, 0.2);
  }
  background-color: #f59e0b;
`;

export default function ({ selectedSurvey, surveys, setSurveys }) {
  const [surveyName, setSurveyName] = useState("");
  const [surveyType, setSurveyType] = useState("");
  const [surveyTitle, setSurveyTitle] = useState("");
  const [surveyContent, setSurveyContent] = useState("");
  const [isLoaderShown, setIsLoaderShown] = useState(false);
  const [isModalShown, setIsModalShown] = useState(false);
  const [message, setMessage] = useState("");

  useEffect(() => {
    if (selectedSurvey != null) {
      setSurveyType(surveys[selectedSurvey].details.SurveyType);
      setSurveyName(surveys[selectedSurvey].details.SurveyName);
      setSurveyTitle(surveys[selectedSurvey].details.SurveyTitle);
      setSurveyContent(surveys[selectedSurvey].details.SurveyContent);
    }
  }, [selectedSurvey]);

  const validationFunc = (validationFields) => {
    for (let i = 0; i < validationFields.length; i++) {
      if (validationFields[i].length < 3) return false;
    }
    return true;
  };

  const handleSave = () => {
    const surveyData = {
      userId: 28,
      surveyObj: {
        SurveyID: surveys[selectedSurvey].details.SurveyID,
        SurveyType: surveyType,
        SurveyName: surveyName,
        SurveyTitle: surveyTitle,
        SurveyContent: surveyContent,
      },
    };
    if (validationFunc([surveyName, surveyTitle, surveyContent])) {
      setIsLoaderShown(true);
      axios
        .post(process.env.REACT_APP_BASE_URI + "/doEditSurvey.php", surveyData,authParams())
        .then((res) => {
          setSurveys(res.data.surveys);
          setIsLoaderShown(false);
          setMessage("השינויים נשמרו בהצלחה");
          setIsModalShown(true);
        });
    } else {
      setMessage("אנא מלא את כל השדות");
      setIsModalShown(true);
    }
  };

  const handleDelete = () => {
    const surveyData = {
      userId: 28,
      surveyObj: {
        SurveyId: surveys[selectedSurvey].details.SurveyID,
        Questions: surveys[selectedSurvey].questions,
      },
    };
    setIsLoaderShown(true);
    axios
      .post(process.env.REACT_APP_BASE_URI + "/doDeleteSurvey.php", surveyData,authParams())
      .then((res) => {
        setSurveys(res.data.surveys);
        setIsLoaderShown(false);
      });
  };

  const handleDuplicate = () => {
    const surveyData = {
      userId: 28,
      surveyObj: {
        SurveyId: surveys[selectedSurvey].details.SurveyID,
        Questions: surveys[selectedSurvey].questions,
      },
    };
    setIsLoaderShown(true);
    axios
      .post(
        process.env.REACT_APP_BASE_URI + "/doDuplicateSurvey.php",
        surveyData,authParams()
      )
      .then((res) => {
        setSurveys(res.data.surveys);
        setIsLoaderShown(false);
      });
  };

  const handleSurveyAnswers = () => {
    window.location.href =
      "/admin/pdf/surveyReport.php?sid=" +
      surveys[selectedSurvey].details.SurveyID;
  };

  return (
    <Wrapper>
      <Header>
        <Title>הגדרות משוב</Title>
      </Header>
      {selectedSurvey != null && (
        <>
          <SettingLabel>סוג המשוב</SettingLabel>
          <SurveyType
            onChange={(e) => setSurveyType(e.target.value)}
            value={surveyType}
          >
            <option value="0">מכללת שבירו</option>
            <option value="1">משרד החינוך</option>
            <option value="2">משרד הביטחון</option>
          </SurveyType>
          <SettingLabel>שם המשוב (לשימוש פנימי בלבד): </SettingLabel>
          <SettingInput
            onChange={(e) => setSurveyName(e.target.value)}
            value={surveyName}
          ></SettingInput>
          <SettingLabel>כותרת המשוב (מוצג למשתמש במענה על המשוב):</SettingLabel>
          <SettingInput
            onChange={(e) => setSurveyTitle(e.target.value)}
            value={surveyTitle}
          ></SettingInput>
          <SettingLabel>תיאור המשוב (מוצג למשתמש במענה על המשוב):</SettingLabel>
          <Textarea
            onChange={(e) => setSurveyContent(e.target.value)}
            value={surveyContent}
          ></Textarea>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              margin: "5% 0",
            }}
          >
            <div
              style={{
                display: "flex",
                flexWrap: "Wrap",
                justifyContent: "center",
              }}
            >
              <Button onClick={handleSave}>שמירת שינויים</Button>
              <Button
                onClick={() =>
                  (window.location.href =
                    surveys[selectedSurvey].details.SurveyID)
                }
              >
                הצג משוב
              </Button>
              <Button onClick={handleSurveyAnswers}>הצג תוצאות משוב</Button>
              <Button onClick={handleDuplicate}>שכפול משוב</Button>
              <Button onClick={handleDelete}>מחיקת משוב</Button>
            </div>
          </div>
          {isModalShown && (
            <ModalMessage setIsModalShown={setIsModalShown} message={message} />
          )}
          <SettingLabel>כתובת משוב זה לשליחה לתלמידים:</SettingLabel>
          <SettingInput
            readOnly
            style={{ userSelect: "all" }}
            value={`https://survey.shviro-college.co.il/${surveys[selectedSurvey].details.SurveyID}`}
          ></SettingInput>
        </>
      )}
      {isLoaderShown && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
            paddingTop: "10rem",
            backgroundColor: "rgba(0,0,0,0.5)",
          }}
        >
          <div>
            <Loader />
          </div>
        </div>
      )}
    </Wrapper>
  );
}
