import axios from "axios";
import React, { useLayoutEffect, useState,useEffect } from "react";
import { BrowserRouter as Router, Link, useNavigate } from "react-router-dom";
import ModalMessage from "./modal/ModalMessage";
import styled from "styled-components";
import { keyframes } from "styled-components";
import Loader from "./loader/LoaderSpinner";
import { authenticate } from "./requests/authenticate";

const slideIn = keyframes`
  from {
    transform:translateX(100vw);
  }
  to{transform:translateX(0);}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  min-height: 50%;
  animation: ${slideIn} 0.5s;s
`;

const Input = styled.input`
  border-style: none none solid none;
  border-width: 2px;
  background-color: rgba(0, 0, 0, 0.05);
  min-width: 32vw;
  padding: 2%;
  &:focus {
    outline-style: none;
    border-bottom: solid #c026d3 2px;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: space-between;
  height: 30vh;
`;

const Header = styled.h3`
  padding: 0.5em;
  margin: 0;
  color: white;
  background-color: #9c5d8a;
`;

const Button = styled.button`
  padding: 0.5em;
  margin: 0;
  color: white;
  background-color: #9c5d8a;
  font-size: 20px;
  border: none;
  padding: 0.7rem 3rem;
  border-radius: 2rem;
  transition: 0.5s;
  &:hover {
    cursor: pointer;
    box-shadow: 0 1px 5px 2px rgba(0, 0, 0, 0.2);
  }
`;

export default function Login({logInFnc,isLogged}) {
  const [isLoaderShown, setIsLoaderShown] = useState(false);
  const [isModalShown, setIsModalShown] = useState(false);
  const [message, setMessage] = useState("");


  let redirect=useNavigate();

  useEffect(() => {
    logInFnc()
    isLogged==true && redirect('/dashboard')
  }, [isLogged]);

  const validationFunc = (validationFields) => {
    for (let i = 0; i < validationFields.length; i++) {
      if (validationFields[i].length < 3) return false;
    }
    return true;
  };

  let navigate = useNavigate();


  const loginHandle = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    const params = new URLSearchParams();
    params.append("email", form["email"].value);
    params.append("password", form["password"].value);
    if (validationFunc([form["email"].value, form["password"].value])) {
      axios
        .post(process.env.REACT_APP_BASE_URI + "/doLogin.php", params)
        .then(async(res) => {

          if(res.data.auth){
            localStorage.setItem("authToken",res.data.token)
            await authenticate('Login').then(async(res)=>{
              await logInFnc()
   
              
              if (res.data.userId != null) {
                localStorage.setItem("accessLevel", res.data.userAccessLevel);
                navigate("/dashboard");
              }

            })
          }

          
          if (res.data.message == "unauthorized") {
            setMessage("אחד או יותר מהפרטים שהזנת שגויים");
            setIsModalShown(true);
            setIsLoaderShown(false);
          }
})}}
return (
  <div>
    <Header>התחברות</Header>
    <Wrapper>
      <div>
        <h2>התחברות</h2>
        <h3>אנא מלא את כתובת המייל והסיסמא לכניסה למערכת</h3>
      </div>
      <Form onSubmit={(event) => loginHandle(event)}>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "space-between",
            height: "60%",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              flexDirection: "column",
            }}
          >
            <label htmlFor="email">דואר אלקטרוני</label>
            <Input type={"email"} name="email" />
          </div>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "start",
              flexDirection: "column",
            }}
          >
            <label htmlFor="email">סיסמה</label>
            <Input type={"password"} name="password" />
          </div>
        </div>
        <div style={{ width: "100%" }}>
          <Button onClick={() => setIsLoaderShown(true)}>
            {isLoaderShown ? <Loader /> : "התחברות"}
          </Button>
        </div>
      </Form>
    </Wrapper>
    {isModalShown && (
      <ModalMessage setIsModalShown={setIsModalShown} message={message} />
    )}
  </div>
);

}