import React, {useEffect} from 'react'
import styled from 'styled-components'
import { keyframes } from "styled-components";

const slideIn = keyframes`
  from {
    transform:translateY(100vw);
  }
  to{transform:translateY(0);}
`;


const Wrapper = styled.div`
background-color: rgba(0, 0, 0, 0.85);
color: white;
z-index: 20;
margin-top:3%;
padding: 0.5rem 1rem;
width: 90%;
border-radius: 0.5rem;
bottom:0;
animation: ${slideIn} 0.5s;
`

export default function ModalMessage({message, setIsModalShown}) {

    useEffect(() => {
      setTimeout(() => {
        setIsModalShown(false);
      }, 3000);
    }, [])
     

    return (
        <div style={{display:'flex', justifyContent: 'center'}}>
            <Wrapper>
                <p>{message}</p>
            </Wrapper>
        </div>
    )
}
