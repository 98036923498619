import axios from "axios";
import React, { useEffect, useRef, useState } from "react";
import { ReactSortable } from "react-sortablejs";
import {arrayMoveImmutable as arrayMove} from 'array-move';
import styled from "styled-components";
import { keyframes } from "styled-components";
import Modal from "../../modal/Modal";
import { authParams } from "../../requests/authenticate";
import SurveyQuestionSnippet from "./SurveyQuestionSnippet";

const slideIn = keyframes`
  from {
    transform:translateX(-100vw);
  }
  to{transform:translateX(0);}
`;

const Wrapper = styled.div`
  border-radius: 1rem;
  padding: 7px;
  flex: auto;
  max-width: 25rem;
  background-color: white;
  animation: ${slideIn} 0.5s;
`;

const Title = styled.h3`
  padding: 0.5em;
  border-radius: 0.5rem 0.5rem 0 0;
  margin: 0;
  color: white;
`;

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  border-radius: 0.5rem 0.5rem 0 0;
  margin: 0;
  padding: 0.5rem 0.5rem;
  color: white;
  background-color: #9c5d8a;
`;

const Button = styled.button`
  margin: 13% 0 0 15%;
  color: white;
  width: 100%;
  font-size: 12px;
  border: none;
  border-radius: 0.5rem;
  transition: 0.5s;
  &:hover {
    cursor: pointer;
    box-shadow: 0 0px 2px 1px rgba(0, 0, 0, 0.2);
  }
  background-color: #f59e0b;
`;

export default function SurveysQuestions({
  selectedSurvey,
  setSurveys,
  surveys,
}) {
  const [isModalShown, setIsModalShown] = useState(false);
  const [questions, setQuestionItems] = useState([])
  const [selectedQuestion, setSelectedQuestion] = useState(null)


  
  useEffect(() => {
    if(selectedSurvey){
      const sorted = [...selectedSurvey.questions.sort((a,b) => {
        return a.QuestionPosition - b.QuestionPosition 
      })]
      setQuestionItems(sorted)
    }
  },[selectedSurvey])

	//const handle drag sorting
	const handleSort = ({ item, oldIndex, newIndex }) => {
    // console.log(item, oldIndex, newIndex);

    const newQuestions = [...arrayMove(questions,oldIndex, newIndex)]

    const questionsObj = newQuestions
    
    // send new orders to backend
    axios
    .post(process.env.REACT_APP_BASE_URI + "/updateOrder.php", {questionsObj},authParams())
    .then((res) => {
      //update the actual array
      setQuestionItems(questionsObj)
    }).catch(err => {
      //log error
      console.error(err)
    });
	}

  return (
    <Wrapper>
      <Header>
        <Title>שאלות משוב</Title>
        <div>
          <Button
            disabled={selectedSurvey == null}
            onClick={() => {
              setSelectedQuestion(null);
              setIsModalShown(true);
            }}
          >
            הוספת שאלה
          </Button>
        </div>
      </Header>
      {
        selectedSurvey != null && (
          <>
          <div
            style={{
              borderBottom: "2px solid rgba(0, 0, 0, 0.1)",
              marginTop: "0.5rem",
            }}
          ></div>
          <ReactSortable
          list={questions}
          setList={setQuestionItems}
          animation="150"
          // animation={150}
          delayOnTouchStart={true}
          delay={2}
          onEnd={handleSort}
          >
          {questions
          // .sort((a,b) => {
          //   return a.QuestionPosition - b.QuestionPosition 
          // })
          .map(( question, index) => (
            <SurveyQuestionSnippet 
            {...question} 
            onClick={() => {
              setSelectedQuestion(question)
              setIsModalShown(true);
            }}
            surveys={surveys}
            setSurveys={setSurveys}
            key={question.QuestionID}
            />
          ))}
          </ReactSortable>
          </>

        )
      // selectedSurvey != null && (
      //   <>
      //     <div
      //       style={{
      //         borderBottom: "2px solid rgba(0, 0, 0, 0.1)",
      //         marginTop: "0.5rem",
      //       }}
      //     ></div>
      //     {selectedSurvey.questions
      //     .sort((a,b) => {
      //       return a.QuestionPosition - b.QuestionPosition 
      //     })
      //     .map((question,index) => {
      //       return (
      //         <SurveyQuestionSnippet
      //           {...question}
      //           surveys={surveys}
      //           setSurveys={setSurveys}
      //           key={question.QuestionID}
      //           draggable
      //           onDragStart={(e) => (dragItem.current = index)}
      //           onDragEnter={(e) => (dragOverItem.current = index)}
      //           onDragEnd={handleSort}
      //           onDragOver={(e) => e.preventDefault()}
      //         />
      //       );
      //     })}
      //   </>
      // )
      }

      {isModalShown && (
        <Modal
          setIsModalVisible={setIsModalShown}
          surveyId={selectedSurvey.details.SurveyID}
          surveys={surveys}
          setSurveys={setSurveys}
          title={selectedQuestion && selectedQuestion.QuestionName}
          questionId={selectedQuestion && selectedQuestion.QuestionID}
        />
      )}
    </Wrapper>
  );
}
