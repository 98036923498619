import axios from "axios";
import React, { useState,useEffect,useLayoutEffect } from "react";
import { BrowserRouter as Router, Link, useNavigate,Navigate, Outlet, useLocation } from "react-router-dom";
import ModalMessage from "./modal/ModalMessage";
import styled from "styled-components";
import { keyframes } from "styled-components";
import Loader from "./loader/LoaderSpinner";
import { authenticate } from "./requests/authenticate";


const ProtectedRoutes = ({isAuth,isLogged}) => {

  const [ticker, setTicker] = useState(false);
  
  useLayoutEffect(() => {
    isAuth()
  }, []);
  useEffect(() => {
    // setTimeout(() => {
      isAuth()
    //   setTicker(t=>!t)
    // }, 3000);

  }, []);

  return (

      (isLogged ? <Outlet /> : <Navigate to="/" />)
      
)
}



export default ProtectedRoutes;