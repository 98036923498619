// let axios = require("axios");
import axios from "axios";

export const authenticate = async (setting) => {
  return axios
    .get(process.env.REACT_APP_BASE_URI + "/authenticate.php", {
      withCredentials: true,
      headers: {
        authorization: "Bearer " + localStorage.getItem("authToken"),
      },
    })
    .then(async (res) => {
      if (!!setting) {
        if (setting == "Login") res.data = res.data.user;
      }
      return res;
    });
};

export const authParams = () => {
  return {
    withCredentials: true,
    headers: {
      authorization: "Bearer " + localStorage.getItem("authToken"),
    },
  };
};

// exports.authenticate = async (setting) => {
//   return axios
//     .get(process.env.REACT_APP_BASE_URI + "/authenticate.php", {
//       withCredentials: true,
//       headers: {
//         authorization: "Bearer " + localStorage.getItem("token"),
//       },
//     })
//     .then(async (res) => {
//       if (!!setting) {
//         if (setting == "Login") res.data = res.data.user;
//       }
//       return res;
//     });
// };

// exports.authParams = () => {
//   return {
//     withCredentials: true,
//     headers: {
//       authorization: "Bearer " + localStorage.getItem("token"),
//     },
//   };
// };
