import React from "react";
import "./loader.styles.css";

export default function Loader() {
  return (
    <div id="loader_wrapper">
      <div className="ld-box"></div>
      <div className="ld-box"></div>
    </div>
  );
}
