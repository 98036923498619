import axios from "axios";
import React, { useState } from "react";
import styled from "styled-components";
import ModalMessage from "./ModalMessage";
import { keyframes } from "styled-components";
import Surveys from "../dashboard/surveys/Surveys";
import { serialize } from "../utils/helpers";
import Loader from "../loader/LoaderSpinner";
import { authenticate,authParams } from "../requests/authenticate";

const slideIn = keyframes`
  from {
    transform:translateY(100vw);
  }
  to{transform:translateY(0);}
`;

const Wrapper = styled.form`
  position: relative;
  background-color: white;
  padding: 4rem 6rem;
  z-index: 2;
  display: flex;
  flex-direction: column;
  align-content: space-between;
  text-align: right;
  border: 2px solid #808080;
  border-radius: 0.5rem;
  animation: ${slideIn} 0.2s;
`;

const Background = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background-image: linear-gradient(rgba(0, 0, 0, 0.1), rgba(0, 0, 0, 0));
  display: flex;
  justify-content: center;
  align-items: start;
  z-index: 10;
`;

const ExitButton = styled.span`
  position: absolute;
  top: 0;
  left: 0;
  padding: 0.5rem;
  font-weight: 900;
  font-size: 20px;
  &:hover {
    cursor: pointer;
  }
`;

const Input = styled.input`
  border-style: none none solid none;
  border-width: 2px;
  background-color: rgba(0, 0, 0, 0.05);
  padding: 2%;
  &:focus {
    outline-style: none;
    border-bottom: solid #c026d3 2px;
  }
`;

const Select = styled.select`
  border-style: none none solid none;
  border-width: 2px;
  background-color: rgba(0, 0, 0, 0.05);
  width: 20rem;
  padding: 2%;
  &:focus {
    outline-style: none;
    border-bottom: solid #c026d3 2px;
  }
`;

const Button = styled.button`
  color: white;
  width: 100%;
  font-size: 12px;
  border: none;
  transition: 0.5s;
  &:hover {
    cursor: pointer;
    box-shadow: 0 0px 2px 1px rgba(0, 0, 0, 0.2);
  }
  padding: 0.5rem;
`;

const Textarea = styled.textarea`
  border: 2px solid rgba(0, 0, 0, 0.2);
  background-color: rgba(0, 0, 0, 0.05);
  margin: 0;
  resize: vertical;
`;

export default function Modal({
  setIsModalVisible,
  title,
  addSurvey,
  surveys,
  setSurveys,
  surveyId,
  questionId,
}) {
  const [isLoaderShown, setIsLoaderShown] = useState(false);
  const [isModalShown, setIsModalShown] = useState(false);
  const [message, setMessage] = useState("");

  const validationFunc = (validationFields) => {
    for (let i = 0; i < validationFields.length; i++) {
      if (validationFields[i].length < 3) return false;
    }
    return true;
  };

  const loginHandle = (e) => {
    e.preventDefault();
    const form = e.currentTarget;
    const formFields = ["type", "title", "name", "content"];
    if (addSurvey) {
      const surveyObjAddSurvey = {
        userId: 28,
        surveyObj: {
          surveyType: form[formFields[0]].value,
          surveyTitle: form[formFields[1]].value,
          surveyName: form[formFields[2]].value,
          surveyContent: form[formFields[3]].value,
          SurveyUser: 28,
        },
      };
      if (
        validationFunc([
          form[formFields[1]].value,
          form[formFields[2]].value,
          form[formFields[3]].value,
        ])
      ) {
        setIsLoaderShown(true);
        axios
          .post(
            process.env.REACT_APP_BASE_URI + "/addSurvey.php",
            surveyObjAddSurvey,authParams()
          )
          .then((res) => {
            let surveyDetails = res.data.survey;
            surveyDetails.SurveyAnswered = 0;
            setSurveys([
              { details: res.data.survey, questions: [] },
              ...surveys,
            ]);
            setIsModalVisible(false);
            setMessage("השינויים נשמרו בהצלחה");
            setIsModalShown(true);
          });
      } else {
        setIsModalShown(true);
        setMessage("אנא מלא את כל השדות");
      }
    } else {
      const surveyObjQuestion = {
        userId: 28,
        surveyObj: {
          questionSurvey: surveyId,
          questionId: questionId,
          questionType: form["type"].value,
          questionName: form["title"].value,
        },
      };
      if (validationFunc([form[formFields[1]].value])) {
        setIsLoaderShown(true);
        axios
          .post(
            process.env.REACT_APP_BASE_URI +
              (title ? "/doEditQuestion.php" : "/addQuestion.php"),
            surveyObjQuestion,authParams()
          )
          .then((res) => {
            setSurveys(res.data.surveys);
            setIsModalVisible(false);
            setMessage("השינויים נשמרו בהצלחה");
            setIsModalShown(true);
          });
      } else {
        setIsModalShown(true);
        setMessage("אנא מלא את כל השדות");
      }
    }
  };

  const handleDelete = (e) => {
    e.stopPropagation();
    e.preventDefault();
    const surveyObjQuestion = {
      userId: 28,
      surveyObj: {
        questionId: questionId,
      },
    };
    setIsLoaderShown(true);
    axios
      .post(
        process.env.REACT_APP_BASE_URI + "/doDeleteQuestion.php",
        surveyObjQuestion,authParams()
      )
      .then((res) => {
        setSurveys(res.data.surveys);
        setIsModalVisible(false);
      });
  };

  return (
    <Background>
      <Wrapper
        onSubmit={(event) => {
          loginHandle(event);
        }}
      >
        <ExitButton onClick={() => setIsModalVisible(false)}>X</ExitButton>
        {addSurvey ? (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingBottom: "0.7rem",
              }}
            >
              <label htmlFor="type"> סוג המשוב</label>
              <Select name="type" id={"type"}>
                <option value={0}>מכללת שבירו</option>
                <option value={1}>משרד החינוך</option>
                <option value={2}>משרד הבטחון</option>
              </Select>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingBottom: "0.7rem",
              }}
            >
              <label htmlFor="name">{"שם המשוב(לשימוש פנימי בלבד)"}</label>
              <Input type="text" name={"name"} id={"name"} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingBottom: "0.7rem",
              }}
            >
              <label htmlFor="title">
                {"כותרת המשוב(יוצג למשתמש במענה על המשוב)"}
              </label>
              <Input type="text" name="title" id={"title"} />
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingBottom: "0.7rem",
              }}
            >
              <label htmlFor="content">
                {"תיאור המשוב(יוצג למשתמש במענה על המשוב)"}
              </label>
              <Textarea type="text" name={"content"} id={"content"} />
            </div>
            <Button style={{ backgroundColor: "#f59e0b" }}>הוספת משוב</Button>
          </>
        ) : (
          <>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingBottom: "0.7rem",
              }}
            >
              <label htmlFor="type"> סוג השאלה</label>
              <Select name={"type"} id={"type"}>
                <option value="2">שאלת דירוג</option>
                <option value="1">שאלת פתוחה</option>
                <option value="0">כותרת משנה</option>
              </Select>
            </div>
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                paddingBottom: "0.7rem",
              }}
            >
              <label htmlFor="title">כותרת השאלה</label>
              <Input
                type="text"
                name={"title"}
                id={"title"}
                defaultValue={title || ""}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center" }}>
              <Button style={{ backgroundColor: "#f59e0b" }}>
                שמירת שינויים
              </Button>
              {title && (
                <Button
                  onClick={(event) => {
                    event.stopPropagation();
                    handleDelete(event);
                  }}
                  style={{ backgroundColor: "#808080" }}
                >
                  מחיקת שאלה
                </Button>
              )}
            </div>
          </>
        )}
        {isModalShown && (
          <ModalMessage
            setIsModalShown={setIsModalShown}
            message={"אנא מלא את כל השדות"}
          />
        )}
      </Wrapper>
      {isLoaderShown && (
        <div
          style={{
            position: "absolute",
            width: "100%",
            height: "100%",
            top: 0,
            left: 0,
            display: "flex",
            justifyContent: "center",
            paddingTop: "10rem",
            backgroundColor: "rgba(0,0,0,0.5)",
            zIndex: "20",
          }}
        >
          <div>
            <Loader />
          </div>
        </div>
      )}
    </Background>
  );
}
