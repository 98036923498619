import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { BrowserRouter as Router, Link,useNavigate } from "react-router-dom";
import Users from "./users/Users";
import { keyframes } from "styled-components";
import UserSetting from "./users/UserSettings";
import UserSettings from "./users/UserSettings";
import { authenticate,authParams } from "../requests/authenticate";
import axios from "axios";

const slideIn = keyframes`
  from {
    transform:translateX(100vw);
  }
  to{transform:translateX(0);}
`;

const Wrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 0 10px;
  justify-content: space-between;
  animation: ${slideIn} 0.5s;
`;

export default function AccessManagement({}) {
  const [users, setUsers] = useState([]);
  const [clickedUser, setClickedUser] = useState();
  const [isShown, setIsShown] = useState(false);

  const handleClick = (event) => {
    setIsShown((current) => !current);
  };
  let redirect = useNavigate()
  useEffect(() => {
    axios
      .post(process.env.REACT_APP_BASE_URI + "/loadAccessDetails.php", 28,authParams())
      .then((res) => {
          if (res?.data?.access){
            setUsers(res.data.access);
          }
          else redirect('/')

        })

  }, []);
  
  return (
    <Wrapper>
      <Users
        usersData={users}
        setClickedUser={setClickedUser}
        handleClick={handleClick}
      />
      {clickedUser != -1 ? (
        <UserSettings
          user={users[clickedUser]}
          setUsers={setUsers}
          clickedUser={clickedUser}
        />
      ) : (
        <UserSettings clickedUser={clickedUser} setUsers={setUsers} />
      )}
    </Wrapper>
  );
}
