import React from "react";
import { FaUserCircle, FaQuestionCircle } from "react-icons/fa";
import styled from "styled-components";
import { keyframes } from "styled-components";
import { Link } from "react-router-dom";

const slideIn = keyframes`
  from {
    transform:translateX(100vw);
  }
  to{transform:translateX(0);}
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  position: absolute;
  right: 0;
  top: 0;
  height: 100%;
  background-color: white;
  z-index: 10;
  animation: ${slideIn} 0.2s;
`;

const MenuItem = styled.div`
  padding: 0.5rem 0.5rem 0.5rem 3rem;
  border-bottom: 2px solid rgba(0, 0, 0, 0.05);
  display: flex;
  flex-direction: row;
  align-items: center;
  &:hover {
    background-color: rgba(0, 0, 0, 0.05);
    cursor: pointer;
  }
`;

const fadeIn = keyframes`
  from {
    background-color:rgba(0, 0, 0 , 0);
  }
  to{background-color:rgba(0, 0, 0 , 0.1)}
`;

const BackDrop = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  animation: ${fadeIn} 0.5s;
  z-index: 10;
`;

export default function Menu({ isMenuVisible, setIsMenuVisible }) {
  return (
    <BackDrop
      onClick={() => {
        setIsMenuVisible(false);
      }}
    >
      <Wrapper
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        <Link
          style={{ textDecoration: "none", color: "black" }}
          to="/dashboard"
          onClick={() => setIsMenuVisible(!isMenuVisible)}
        >
          <MenuItem>
            <FaQuestionCircle style={{ paddingLeft: "10px" }} />
            <span>ניהול משובים</span>
          </MenuItem>
        </Link>
        <Link
          style={{ textDecoration: "none", color: "black" }}
          to="/access"
          onClick={() => setIsMenuVisible(!isMenuVisible)}
        >
          <MenuItem>
            <FaUserCircle style={{ paddingLeft: "10px" }} />
            <span>ניהול הרשאות גישה</span>
          </MenuItem>
        </Link>
      </Wrapper>
    </BackDrop>
  );
}
