import "./App.css";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./components/Login";
import Dashboard from "./components/dashboard/Dashboard";
import Topbar from "./components/Topbar";
import PageNotFound from "./components/PageNotFound";
import { useEffect, useState,useLayoutEffect } from "react";
import Menu from "./components/menu/Menu";
import AccessManagement from "./components/access/AccessManagement";
import ProtectedRoutes from "./components/ProtectedRoutes";
import { authenticate } from "./components/requests/authenticate";

function App() {
  const [isMenuVisible, setIsMenuVisible] = useState(false);
  const [isLogged, setisLogged] = useState(false);
  const [ticker, setTicker] = useState(false);

    let logInFnc = async()=>{

      await authenticate().then((res)=>{
        if (res?.data?.auth === true) {
          setisLogged(t=>true)
          return true
        }
        else{
          setisLogged(t=>false)
          return false
        }
    
      })

    }


    useEffect(() => {
      // setTimeout(() => {   
        logInFnc()
        // setTicker(t=>!t)
      // }, 3000);
    }, [])

  return (
    <div className="App">
      <Topbar
        setIsMenuVisible={setIsMenuVisible}
        isMenuVisible={isMenuVisible}
        setisLogged={setisLogged}
      />
      {isMenuVisible && (
        <Menu
          setIsMenuVisible={setIsMenuVisible}
          isMenuVisible={isMenuVisible}
        />
      )}
      <Routes>
        <Route path="/" element={<Login logInFnc={logInFnc} isLogged={isLogged}/>} />
        <Route element={<ProtectedRoutes isAuth={logInFnc} isLogged={isLogged}/>}>
          <Route path="/access" element={<AccessManagement />} />
          <Route exact path="/dashboard" element={<Dashboard />} />
        </Route>
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default App;
