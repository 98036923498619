import React, { useEffect, useState } from "react";
import questIcon from "../../../assets/img/question-mark.png";
import styled from "styled-components";

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  height: 50px;
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

export default function SurveyQuestionSnippet(props) {
  const questionType =
    props.QuestionType == 0
      ? "כותרת משנה"
      : props.QuestionType == 1
      ? "שאלה פתוחה"
      : "שאלת דירוג";
  return (
    <>
      <Wrapper {...props}>
        <div style={{ flex: 0.5 }}>
          <img width={"40px"} height={"40px"} src={questIcon} />
        </div>
        <div style={{ textAlign: "right", flex: "3" }}>
          <p>{props.QuestionName}</p>
          <p style={{ opacity: 0.8, zIndex: 1 }}>{questionType}</p>
        </div>
        <span>{">"}</span>
      </Wrapper>
    </>
  );
}
