import React from "react";
import "./loader.styles.css";

export default function LoaderSpinner() {
  return (
    <div id="ld-spinner-wrapper">
      <div className="dot"></div>
      <div className="dot"></div>
      <div className="dot"></div>
    </div>
  );
}
