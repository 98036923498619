import React from "react";
import userIcon from "../../../assets/img/icon-access.png";
import { FaUserCircle, FaQuestionCircle } from "react-icons/fa";
import styled from "styled-components";

const FileIconWrapper = styled.div`
  position: relative;
  display: flex;
  align-items: end;
  height: 50px;
  z-index: -1;
`;

const Wrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 2px solid rgba(0, 0, 0, 0.1);
  padding: 0.5rem;
  height: 50px;
  &:hover {
    cursor: pointer;
    background-color: rgba(0, 0, 0, 0.05);
  }
`;

const Notifcation = styled.span`
  dispaly: flex;
  align-items: center;
  border-radius: 100%;
  background-color: red;
  position: absolute;
  right: 0;
  top: 0;
  padding: 1px 7px;
  color: white;
  font-weight: 500;
`;

export default function UserSnippet({
  user,
  setClickedUser,
  index,
  setBgIndex,
  bgIndex,
}) {
  const boldText = ["900", "400"];

  return (
    <Wrapper
      onClick={() => {
        setClickedUser(index);
        setBgIndex(index);
      }}
      style={{ fontWeight: boldText[bgIndex == index ? 0 : 1] }}
    >
      <img src={userIcon} style={{ width: "2rem", height: "2rem" }} />
      <div>
        <p>{user.AccessFullName}</p>
        <p>
          {user.AccessLevel == 2
            ? `מדריך`
            : user.AccessLevel == 1
            ? "מנהל"
            : "מנהל על"}
        </p>
      </div>
      <span>{">"}</span>
    </Wrapper>
  );
}
