import React from "react";
import {useNavigate } from "react-router-dom";
import styled from "styled-components";

const Wrapper = styled.div`
  position: relative;
  border-radius: 1rem;
  padding: 7px;
  background-color: white;
  flex: 3;
  text-align: center;
`;

const Header = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border-radius: 0.5rem 0.5rem 0 0;
  padding: 0.5rem 0.5rem;
  color: white;
  background-color: #9c5d8a;

`;

const Title = styled.h3`
  padding: 0.5em;
  border-radius: 0.5rem 0.5rem 0 0;
  margin: 0;
  color: white;
`;

const Button = styled.button`
  padding: 0.5em;
  margin: 3%;
  color: white;
  background-color: #9c5d8a;
  font-size: 20px;
  border: none;
  padding: 0.7rem 3rem;
  border-radius: 2rem;
  transition: 0.5s;
  &:hover {
    cursor: pointer;
    box-shadow: 0 1px 5px 2px rgba(0, 0, 0, 0.2);
  }
`;

export default function () {
    let navigate = useNavigate();
  return (
    <Wrapper>
      <Header>
        <Title>Error 404: Page not found</Title>
      </Header>
      <div>
          <p>העמוד המבוקש לא קיים</p>
      </div>
      <div>
      <Button onClick={() => navigate('/')}>חזרה לעמוד הראשי</Button>
      </div>
    </Wrapper>
  );
}
