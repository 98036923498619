import React, { useEffect } from "react";
import styled from "styled-components";
import { FaSignOutAlt } from "react-icons/fa";
import { BrowserRouter as Router, Link } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Header = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 0.5em;
  margin: 0;
  color: white;
  background-color: #9c5d8a;
`;

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
`;

const BurgerSlice = styled.span`
  background-color: white;
  width: 100%;
  height: 2px;
`;

const BurgerContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 1.7rem;
  height: 1.5rem;
  &:hover {
    cursor: pointer;
  }
`;

export default function Topbar({ setIsMenuVisible, isMenuVisible,setisLogged }) {
  let location = useLocation();

  return (
    <>
      {location.pathname != "/" && (
        <Header>
          <BurgerContainer onClick={() => setIsMenuVisible(!isMenuVisible)}>
            <BurgerSlice></BurgerSlice>
            <BurgerSlice></BurgerSlice>
            <BurgerSlice></BurgerSlice>
          </BurgerContainer>
          <span style={{ fontWeight: "500" }}>מערכת משובים מכללת שבירו</span>
          <Link to="/">
            <FaSignOutAlt
            onClick={()=>{
              localStorage.removeItem('authToken')
              localStorage.removeItem('accessLevel')
              setisLogged(s=>false)
            }}
            style={{ color: "white", transform: "rotate(180deg)" }}
              />
            </Link>
        </Header>
      )}
    </>
  );
}
